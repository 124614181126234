<template>
    <div>
        <el-dialog
            width="80%"
            :visible.sync="showDialog"
            :title="title"
            :modal-append-to-body="true"
            :append-to-body="true"
            style="text-align:left;"
            @close="closeDialog"
            :close-on-click-modal="false"
            >
            <div class="title1"><h3>订单信息</h3></div>
            <div class="user-panel" v-loading="loading">
                <el-form ref="form" :model="formModel" >
                    <el-row :gutter="20" >
                        <el-col :span="8">
                            <div class="grid-content bg-purple">
                                <span class="fontText">订单编号</span> 
                                <span class="data">{{formModel.outOrderNo}}</span>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <span class="fontText">交易流水号</span> 
                            <span class="data">{{formModel.transactionId}}</span>
                        </el-col>
                        <el-col :span="8">
                            <span class="fontText">创建时间</span> 
                            <span class="data">{{formModel.createTime}}</span>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" >
                        <el-col :span="8">
                            <div class="grid-content bg-purple">
                                <span class="fontText">支付时间</span> 
                                <span class="data">{{formModel.payTime}}</span>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <span class="fontText" style="margin-left:13px">学生姓名</span> 
                            <span class="data">{{formModel.name}}</span>
                        </el-col>
                        <el-col :span="8">
                            <span class="fontText">联系方式</span> 
                            <span class="data">{{formModel.contactPhone}}</span>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" >
                        <el-col :span="8">
                            <div class="grid-content bg-purple">
                                <span class="fontText">所属学校</span> 
                                <span class="data">{{formModel.schoolName}}</span>
                            </div>
                        </el-col>
                         <el-col :span="8" v-if="formModel.payStatus==10">
                            <div class="grid-content bg-purple">
                                <el-popover
                                placement="right"
                                width="300"
                                trigger="click">
                                
                                <div id="qrcode" ref="qrcode"></div>
                                <el-button slot="reference" @click="showQRcode">微信支付二维码</el-button>
                                </el-popover>
                            </div>
                        </el-col>
                    </el-row>
                 </el-form>
                <el-table
                    ref="formTable"
                    :data="tableData"
                    v-loading="loading"
                    element-loading-text="拼命加载中"
                    border
                    stripe
                >
                    <el-table-column prop="goodName" label="商品信息" fixed="left"></el-table-column>
                    <el-table-column prop="goodTypeName" label="商品类型" fixed="left"></el-table-column>
                    <el-table-column prop="payStatusStr" label="状态" fixed="left"></el-table-column>
                    <el-table-column prop="cost" label="商品单价" fixed="left"></el-table-column>
                    <el-table-column prop="goodNum" label="商品数量" fixed="left"></el-table-column>
                    <el-table-column prop="payFee" label="实付金额" fixed="left"></el-table-column>
                    <el-table-column prop="refundFee" label="退款金额" fixed="left"></el-table-column>
                    <el-table-column prop="payName" label="支付方式" fixed="left"></el-table-column>
                    <el-table-column label="操作" fixed="right" >
                        <template slot-scope="{row}">
                            <span v-if="row.payStatus==10"><el-link type="primary"  @click="closeOrder(row)">关闭订单</el-link></span>
                            <div v-if="row.payStatus==20"><el-link type="danger" @click="offlineRefund(row,'0')">线上退款</el-link></div>
                            <div v-if="row.payStatus==20"><el-link type="danger" @click="offlineRefund(row,'1')">线下退款</el-link></div>
                        </template>
                    </el-table-column>
                </el-table>

            </div>
            <div class="title2"><h3>订单操作记录</h3></div>
                <el-table
                    ref="formTable"
                    :data="tableData1"
                    v-loading="loading"
                    element-loading-text="拼命加载中"
                    border
                    stripe
                >
                    <el-table-column prop="createTime" label="操作时间" fixed="left" min-width="25%"></el-table-column>
                    <el-table-column prop="content" label="操作记录" fixed="left"></el-table-column>
                </el-table>
        </el-dialog>
        <order-refundFee-detail
            v-if="showModal2"
            :businessKey="businessKey"
            :parentId="selectedRecord.id"
            :title="modalTitle"
            @close="onDetailModalClose2"
        ></order-refundFee-detail>
    </div>
</template>
<script>
import Constant from "@/constant";
import orderApi from "@/api/base/order";
import orderRefundFeeDetail from "./order-refundFee-detail";
import QRCode from "qrcodejs2";
export default {
    props: ["businessKey", "title"],
    data(){
        return{
            formModel:{
                outOrderNo:"",
            },
            showDialog: true,
            showModal2: false,
            loading: false,
            tableData:[],
            tableData1:[],
        }
    },
    methods: {
        closeDialog() {
            this.$emit("close", false);
        },
        closeOrder(row){
            this.$confirm("是否确认关闭订单?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
            })
            .then(() => {
                var self = this;
                var formData = new FormData();

                formData.append("id", row.id);
                formData.append("payStatus", 30);

                orderApi.updateOrder(formData).then(response => {
                    var jsonData = response.data;

                    var count = jsonData.data;

                    if(count==1){
                        orderApi.goodsList(self.businessKey).then(response => {
                                this.loading = false;
                                var jsonData = response.data;

                                console.log(jsonData);

                                this.tableData = jsonData.data;
                        });
                    }
                })
            })
        },
        offlineRefund(row,isOff){
            if(isOff=="0"){
                this.modalTitle = "退款金额";
                this.businessKey = row.id;
                this.selectedRecord = row;
                this.showModal2 = true;
            }
            else{
                this.$confirm("是否确认退款?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                .then(() => {
                    var formData = new FormData();

                    formData.append("id", row.id);
                    formData.append("payStatus", 40);
                    formData.append("isOff", isOff);

                    orderApi.updateOrder(formData).then(response => {
                        var jsonData = response.data;

                        if (jsonData.result) {
                            var count = jsonData.data;

                            if(count==1){
                                this.$message.success("退款成功！");
                                orderApi.goodsList(self.businessKey).then(response => {
                                        this.loading = false;
                                        var jsonData = response.data;

                                        console.log(jsonData);

                                        this.tableData = jsonData.data;
                                });

                                orderApi.orderLog(self.businessKey).then(response => {
                                        this.loading = false;
                                        var jsonData = response.data;

                                        console.log(jsonData);

                                        this.tableData1 = jsonData.data;
                                });
                            }
                        } else {
                            this.$message.error(jsonData.message + "");
                        }
                    })
                })
            }


            // this.$confirm("是否确认退款?", "提示", {
            //     confirmButtonText: "确定",
            //     cancelButtonText: "取消",
            //     type: "warning"
            // })
            // .then(() => {
            //     var self = this;
            //     var formData = new FormData();

            //     formData.append("id", row.id);
            //     formData.append("payStatus", 40);
            //     formData.append("isOff", isOff);

            //     orderApi.updateOrder(formData).then(response => {
            //         var jsonData = response.data;

            //         if (jsonData.result) {

            //             var count = jsonData.data;

            //             if(count==1){
            //                 this.$message.success("退款成功！");
            //                 orderApi.goodsList(self.businessKey).then(response => {
            //                         this.loading = false;
            //                         var jsonData = response.data;

            //                         console.log(jsonData);

            //                         this.tableData = jsonData.data;
            //                 });

            //                 orderApi.orderLog(self.businessKey).then(response => {
            //                         this.loading = false;
            //                         var jsonData = response.data;

            //                         console.log(jsonData);

            //                         this.tableData1 = jsonData.data;
            //                 });
            //             }
            //         } else {
            //             this.$message.error(jsonData.message + "");
            //         }
            //     })
            // })
        },
        onDetailModalClose2(retObj) {
            //保存成功后回调
            this.showModal2 = false;

            if (retObj.result) {
                var retData = retObj.data;

            }
        },

        qrcode(qWidth, qHeight, qText, qRender) {
            let qrcode = new QRCode("qrcode", {
                width: qWidth,
                height: qHeight,
                text: qText,
                render: qRender
            });
        },

        /**
         * @description 点击显示二维码
         */
        showQRcode() {
            //二维码初始化 点击一次添加一个二维码
            this.$refs.qrcode.innerHTML = "";
            this.$nextTick(function() {
                this.qrcode(300, 300, this.formModel.wePayCode, "canvas");
            });
        }

    },
    async mounted() {
        console.log("mounted");

        var self = this;

        (function() {
            return orderApi.detail(self.businessKey);
        })()
        .then(response => {
            var jsonData = response.data;

            if (jsonData.result) {
                self.formModel = jsonData.data;
            } else {
                self.$message.error(jsonData.message + "");
            }
        })
        .catch(error => {
            self.$message.error(error + "");
        });

        orderApi.goodsList(self.businessKey).then(response => {
                this.loading = false;
                var jsonData = response.data;

                console.log(jsonData);

                this.tableData = jsonData.data;
        });

        orderApi.orderLog(self.businessKey).then(response => {
                this.loading = false;
                var jsonData = response.data;

                console.log(jsonData);

                this.tableData1 = jsonData.data;
        });
    },
    components: {
        "order-refundFee-detail": orderRefundFeeDetail,
    },
}
</script>
<style scoped>
.user-panel {
  margin: 10px auto;
  margin-left: 20px;
}
.data{
    margin-left: 50px;
    font-weight:bold
}
.el-row {
margin-bottom: 20px;
}
.el-col {
border-radius: 4px;
}
.grid-content {
border-radius: 4px;
min-height: 36px;
}
.title1{
    margin-bottom: 40px;
}
.title2{
    margin-top: 40px;
    margin-bottom: 40px;
}
.emqrcode {
  width: 100%;
  background-color: #f00;
}
</style>