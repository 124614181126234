<template>
    <div class="order-list">
        <el-breadcrumb separator=">">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>
                <a href="#">增值服务</a>
            </el-breadcrumb-item>
            <el-breadcrumb-item>
                <a href="#">订单管理</a>
            </el-breadcrumb-item>
        </el-breadcrumb>
        <el-divider></el-divider>
        <div v-show="queryFormVisible">
            <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
                <el-form-item label="商品名称" prop="goodName">
                    <el-input v-model="queryModel.goodName" size="mini" style="width:150px;"/>
                </el-form-item>
                <el-form-item label="商品类型" prop="goodTypeId">
                    <el-select v-model="queryModel.goodTypeId" placeholder="请选择" size="mini" style="width:150px;">
                        <el-option
                            v-for="item in goodTypeList"
                            :key="item.id"
                            :label="item.goodsName"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="订单编号" prop="outOrderNo">
                    <el-input v-model="queryModel.outOrderNo" size="mini" style="width:150px;"/>
                </el-form-item>
                <el-form-item label="交易流水号" prop="transactionId">
                    <el-input v-model="queryModel.transactionId" size="mini" style="width:150px;"/>
                </el-form-item>
                <el-form-item label="订单状态" prop="payStatus">
                    <el-select v-model="queryModel.payStatus" placeholder="请选择" size="mini" style="width:150px;">
                        <el-option
                            v-for="item in statusList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否关闭" prop="isClose">
                    <el-select v-model="queryModel.isClose" placeholder="请选择" size="mini" style="width:150px;">
                        <el-option key="0" label="未关闭" value="0"></el-option>
                        <el-option key="1" label="已关闭" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="学生姓名" prop="name">
                    <el-input v-model="queryModel.name" size="mini" style="width:150px;"/>
                </el-form-item>
                <!-- <el-form-item label="所属学校" prop="schoolId">
                    <el-select v-model="queryModel.schoolId" 
                    filterable
                    clearable
                    placeholder="请选择" size="mini" style="width:150px;">
                        <el-option
                            v-for="item in schoolList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="学校/班级" prop="schoolId" >
                    <el-cascader
                    v-model="queryModel.schoolId"
                    style="width:350px"
                    placeholder="请选择"
                    size="mini"
                    :options="schoolClassList"
                    filterable
                    change-on-select
                    ></el-cascader>
                </el-form-item>
                <el-form-item label="下单学校" prop="orderSchoolId">
                    <el-select v-model="queryModel.orderSchoolId" 
                    filterable
                    clearable
                    placeholder="请选择" size="mini" style="width:150px;">
                        <el-option
                            v-for="item in schoolList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="创建时间" prop="createTimeRanges">
                    <el-date-picker
                    size="mini"
                    v-model="queryModel.createTimeRanges"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button
                    type="primary"
                    size="mini"
                    icon="ios-search"
                    @click="changePage(1,0)"
                    :loading="loading"
                    >查询</el-button>&nbsp;
                    <el-button
                        type="info"
                        size="mini"
                        style="margin-left: 8px"
                        @click="handleReset('queryForm')"
                    >重置</el-button>
                </el-form-item>            
            </el-form>
            <el-divider></el-divider>
        </div>
        <el-row class="button-group">
            <el-button
                type="primary"
                size="small"
                plain
                icon="el-icon-upload2"
                @click="toggleQueryForm()"
            >{{queryFormVisible ? '隐藏' : '显示'}}查询面板</el-button>
            <el-button
                type="primary"
                size="small"
                plain
                icon="el-icon-upload2"
                @click="batchImportVisible = true"
            >导入订单</el-button>
            <el-button
                type="primary"
                size="small"
                plain
                icon="el-icon-download"
                @click="downloadCSV"
            >导出数据</el-button>
        </el-row>
        <el-table
            ref="formTable"
            :data="tableData"
            v-loading="loading"
            :height="tableHeight"
            element-loading-text="拼命加载中"
            @sort-change="sortChange"
            stripe
        >
            <el-table-column type="index" label="序号" width="60">
                <template slot-scope='scope'>
                    {{(pageIndex - 1)*pageSize + scope.$index + 1}}
                </template>
            </el-table-column>
            <el-table-column prop="name" label="学生姓名" width="100"></el-table-column>
            <el-table-column prop="payFee" label="实付金额(元)" width="100"></el-table-column>
            <el-table-column prop="refundFee" label="退款金额"  width="100"></el-table-column>
            <el-table-column prop="payTime" label="支付时间" sortable="custom" width="150"></el-table-column>
            <el-table-column prop="goodName" label="商品信息" width="180"></el-table-column>
            <el-table-column prop="contactPhone" label="联系方式" width="120"></el-table-column>
            <el-table-column prop="schoolName" label="所属学校" width="250"></el-table-column>
            <el-table-column prop="orderSchoolName" label="下单学校" width="250"></el-table-column>
            <el-table-column prop="gradeName" label="年级" width="120"></el-table-column>
            <el-table-column prop="className" label="班级" width="120"></el-table-column>
            <el-table-column prop="goodTypeName" label="商品类型" width="100"></el-table-column>
            <el-table-column prop="goodNum" label="商品数量"></el-table-column>
            <el-table-column prop="payName" label="支付方式"></el-table-column>      
            <el-table-column prop="outOrderNo" label="订单编号" width="160"></el-table-column>
            <el-table-column prop="transactionId" label="交易流水号" width="160"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" sortable="custom" width="150"></el-table-column>
            <el-table-column label="订单状态" width="200" fixed="right">
                <template slot-scope="{row}">
                    <span v-if="row.payStatus=='10'">
                        等待买家付款
                        <div>
                            <el-link type="primary"  @click="closeOrder(row)">关闭订单</el-link>
                            &nbsp;
                        </div>
                    </span>
                    <span v-else-if="row.payStatus=='20'">
                        已付款
                        <div>
                            <!-- <el-link type="danger" @click="offlineRefund(row,'0')">线上退款</el-link> -->
                            <el-link type="danger" @click="offlineRefund(row,'0')">线上退款</el-link>
                            &nbsp;
                            <el-link type="danger" @click="offlineRefund(row,'1')">线下退款</el-link>
                        </div>
                    </span>
                    <span v-else-if="row.payStatus=='30'">
                        已关闭
                        &nbsp;
                    </span>
                    <span v-else-if="row.payStatus=='40'" style="color:red">
                        已退款
                        &nbsp;
                    </span>
                    <span v-else-if="row.payStatus=='50'" style="color:red">
                        已部分退款
                        &nbsp;
                    </span>
                    <div v-if="row.id!=null"> 
                        <el-link type="primary"  @click="handleDetail(row)">详情</el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:center;">
            <el-pagination
                :current-page.sync="pageIndex"
                :total="totalElements"
                :page-sizes="pageSizeList"
                @current-change="changePage"
                @size-change="pageSizeChange"
                layout="total, sizes, prev, pager, next,  jumper"
            ></el-pagination>
        </div>
        <order-detail
            v-if="showModal"
            :businessKey="businessKey"
            :parentId="selectedRecord.id"
            :title="modalTitle"
            @close="onDetailModalClose"
        ></order-detail>
        <order-refundFee-detail
            v-if="showModal2"
            :businessKey="businessKey"
            :parentId="selectedRecord.id"
            :title="modalTitle"
            @close="onDetailModalClose2"
        ></order-refundFee-detail>

    <!--批量导入S-->
    <el-dialog
      title="批量导入订单"
      :visible.sync="batchImportVisible"
      :modal-append-to-body="false"
      style="text-align: left;"
      :close-on-click-modal="false"
    >
      <el-form label-width="150px">
        <el-form-item label="模板下载">
          <el-link
            href="http://rccs.oss-cn-hangzhou.aliyuncs.com/smart/personInfo/2020/9/order_template.xls"
            type="primary"
            target="_blank"
          >下载订单模板</el-link>
        </el-form-item>
        <el-form-item label="上传文件">
          <el-upload
            class="upload-demo"
            accept=".xls"
            :action="uploadUrlXls"
            :headers="headers"
            :on-preview="handleBatchImportPreview"
            name="uploadFile"
            :multiple="true"
            :limit="1"
            :on-remove="handleBatchImportRemove"
            :before-remove="beforeBatchImportRemove"
            :before-upload="beforeUpload"
            :on-exceed="handleBatchImportExceed"
            :on-success="handleBatchImportSuccess"
            :file-list="batchImportFileList"
          >
            <el-button size="small" type="primary" :loading="xlsLoading">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传xls文件，且不超过500kb</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="batchImportVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
    <!--批量导入E-->
    </div>
</template>
<script>
import Constant from "@/constant";
import orderApi from '@/api/base/order'
import detentionWarningApi from '@/api/bus/detentionWarning'
import registrationFeeApi from "@/api/bus/registrationFee";
import goodsInfoApi from "@/api/base/goodsInfo";
import orderDetail from "./order-detail";
import orderRefundFeeDetail from "./order-refundFee-detail";
import { getToken } from "@/utils/auth"; // get token from cookie
import pageUtil from "@/utils/page";

export default {
    name:"BaseOrderList",
    data(){
        return{
            queryModel:{
                name:"",
                goodName:"",
                goodTypeId:"",
                schoolId:"",
                orderSchoolId:"",
                payStatus:"",
                outOrderNo:"",
                createTimeRanges:"",
                transactionId:"",
                isClose:"0",
            },
            formModel:{
                id:"",
                refundFee:"",
            },
            ruleValidate:{
                refundFee: [{ required: true, message: "退款金额不能为空", trigger: "blur" }],
            },
            payFee:"",
            schoolClassList: [],
            schoolList:[],
            goodTypeList:[],
            statusList:[{
                label:"未支付",
                value:"10"
            },{
                label:"已付款",
                value:"20"
            },{
                label:"已退款",
                value:"40"
            },{
                label:"已关闭",
                value:"30"
            },],
            loading: false,
            tableData: [],
            multipleSelection: [],
            pageIndex: 1,
            pageSize: 10,
            totalElements: 0,
            tableHeight: 400,
            pageSizeList: [10,30,50,100],
            showModal: false,
            showModal2: false,
            batchImportVisible: false,
            batchImportFileList: [],
            field: "",
            direction: "",
            uploadUrlXls:
                Constant.serverUrl + "/base/order/importOrderXls",
            headers: {
                Authorization: getToken()
            },
            xlsLoading: false,
            queryFormVisible: true
        }
    },
    methods: {
        toggleQueryForm(){
            this.queryFormVisible = !this.queryFormVisible;

            this.$nextTick(()=>{
                this.tableHeight = pageUtil.autoAdjustHeight(this.$refs.formTable.$el);
            });
        },
        changePage(pageIndex,exportFlag) {
            var self = this;
            this.loading = true;
            var formData = new FormData();

            formData.append("pageIndex", this.pageIndex);
            formData.append("pageSize", this.pageSize);
            formData.append("goodName", this.queryModel.goodName);
            formData.append("goodTypeId", this.queryModel.goodTypeId);
            formData.append("outOrderNo", this.queryModel.outOrderNo);
            formData.append("payStatus", this.queryModel.payStatus);
            formData.append("name", this.queryModel.name);
            formData.append("schoolId", this.queryModel.schoolId);
            formData.append("orderSchoolId", this.queryModel.orderSchoolId);
            formData.append("createTimeRanges", this.queryModel.createTimeRanges);
            formData.append("transactionId", this.queryModel.transactionId);
            formData.append("isClose", this.queryModel.isClose);
            if(exportFlag!=null&&exportFlag!=""){
                formData.append("exportFlag", exportFlag);
            }

            if (this.direction != null) {
                formData.append("direction", this.direction);
            }

            orderApi.pageList(formData).then(response => {
                this.loading = false;
                var jsonData = response.data;

                if(jsonData.result) {
                    if(exportFlag){
                        //导出
                        self.$message({
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                            duration: 60000,
                            type: "success"
                        });

                    }
                    else{
                    //分页查看
                        console.log(jsonData);

                        this.tableData = jsonData.data.data;
                        this.totalElements = jsonData.data.recordsTotal;

                        this.pageIndex = pageIndex;
                    }
                }
                else{
                    self.$message.warning(jsonData.message);
                }


            });
        },
        pageSizeChange(pageSize) {
            this.pageSize = pageSize;

            this.$nextTick(()=>{
                this.changePage(1,0);
            });
        },
        handleReset(name) {
            this.$refs[name].resetFields();
        },
        onDetailModalClose(retObj) {
            //保存成功后回调
            this.showModal = false;

            if (retObj.result) {
                var retData = retObj.data;

                this.changePage(this.pageIndex,0);

            }
        },
        onDetailModalClose2(retObj) {
            //保存成功后回调
            this.showModal2 = false;

            if (retObj.result) {
                var retData = retObj.data;

                this.changePage(this.pageIndex,0);

            }
        },
        handleDetail(record){
            this.modalTitle = "订单详情";
            this.operation = "detail";
            this.businessKey = record.id;
            this.selectedRecord = record;
            this.showModal = true;
        },
        closeOrder(row){
            this.$confirm("是否确认关闭订单?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
            })
            .then(() => {
                var formData = new FormData();

                formData.append("id", row.id);
                formData.append("payStatus", 30);

                orderApi.updateOrder(formData).then(response => {
                    var jsonData = response.data;

                    var count = jsonData.data;

                    if(count==1){
                        this.changePage(this.pageIndex,0)
                    }
                })
            })
        },
        offlineRefund(row,isOff){
            if(isOff=="0"){
                this.modalTitle = "退款金额";
                this.businessKey = row.id;
                this.selectedRecord = row;
                this.showModal2 = true;
            }
            else{
                this.$confirm("是否确认退款?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                .then(() => {
                    var formData = new FormData();

                    formData.append("id", row.id);
                    formData.append("payStatus", 40);
                    formData.append("isOff", isOff);

                    orderApi.updateOrder(formData).then(response => {
                        var jsonData = response.data;

                        if (jsonData.result) {
                            var count = jsonData.data;

                            if(count==1){
                                this.$message.success("退款成功！");
                                this.changePage(this.pageIndex,0)
                            }
                        } else {
                            this.$message.error(jsonData.message + "");
                        }
                    })
                })
            }
        },
        //批量导入-预览
        handleBatchImportPreview(file) {
        console.log(file.url);
        },
        //批量导入-移除
        handleBatchImportRemove(file, fileList) {
        console.log(file, fileList);
        },
        //批量导入-移除前操作
        beforeBatchImportRemove(file, fileList) {
        console.log(file, fileList);
        },
        //批量导入-文件超出个数限制时的钩子
        handleBatchImportExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择 3 个文件，本次选择了 ${
                files.length
                } 个文件，共选择了 ${files.length + fileList.length} 个文件`
            );
        },

        beforeUpload(file, fileList) {
            //导入前判断
            var self = this;
            self.xlsLoading = true;
            return true;
        },


        //批量导入-上传成功
        handleBatchImportSuccess(response, file, fileList) {
            var self = this;
            self.xlsLoading = false;
            if (response.result) {
                self.$message.success(response.message);
                this.batchImportFileList = [];
                this.changePage(1,0);
                this.batchImportVisible = false;
            } else {
                //this.$message.error(response.message);
                this.batchImportFileList = [];
                this.changePage(1,0);

                if (response.data != null) {
                //下载有错误信息提示的报表
                //window.open(response.data);
                self.$message({
                    showClose: true,
                    dangerouslyUseHTMLString: true,
                    message:
                    response.message +
                    `,<a href="${response.data}" target="_blank">点击下载未导入的数据报表</a>&nbsp;`,
                    duration: 30000
                });
                }
            }
        },
        sortChange(data) {
            console.log(data);

            this.field = data.column.sortBy;
            this.direction = data.order == "ascending" ? "asc" : "desc";

            this.changePage(this.pageIndex,0);
        },
        downloadCSV() {
            this.changePage(1,1);
            // //批量导出
            // var BOM = "\uFEFF";

            // var csv = [];

            // var titles = ["序号","商品信息","学生姓名","联系方式","所属学校","商品类型","商品数量","支付方式","实付金额","订单编号","交易流水号","创建时间","支付时间","订单状态"];

            // csv.push(titles.join("\t,"));

            // this.tableData.forEach((item)=>{
            //     var arr = [];
                
            //     arr.push(csv.length);
            //     arr.push(item.goodName);
            //     arr.push(item.name);
            //     arr.push(item.contactPhone + "\t");
            //     arr.push(item.schoolName);
            //     arr.push(item.goodTypeName);
            //     arr.push(item.goodNum);
            //     arr.push(item.payName);
            //     arr.push(item.payFee);
            //     arr.push(item.outOrderNo+ "\t");
            //     arr.push(item.transactionId+ "\t");
            //     arr.push(item.createTime+ "\t");
            //     arr.push(item.payTime+ "\t");

            //     var payStatus = "";

            //     if(item.status=="10"){
            //         payStatus = "等待买家付款";   
            //     }
            //     else if(item.status=="20"){
            //         payStatus = "已付款";   
            //     }
            //     else if(item.status=="30"){
            //         payStatus = "已关闭";   
            //     }
            //     else if(item.status=="40"){
            //         payStatus = "已退款";   
            //     }

            //     arr.push(payStatus);

            //     csv.push(arr.join(","));
            // });

            // var csvStr = csv.join("\n");

            // var link = document.createElement("a");
            // link.setAttribute("href","data:attachment/csv;charset=utf-8," + BOM + encodeURIComponent(csvStr));
            // link.setAttribute("target","_blank");
            // link.setAttribute("download","导出报表.csv");
            // link.click();
        },
        getSummaries(param) {        
            const { columns, data } = param;
            const sums = [];

            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '总计';
                    return;
                }
                
                const values = data.map(item => Number(item[column.property]));

                if(column.property=="payFee"){
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        
                        if (!isNaN(value)) {
                            return prev + curr;
                        } else {
                            return prev;
                        }
                    }, 0);

                    console.log("sums["+index+"]" + sums[index]);

                    var num = new Number(sums[index]);
                    sums[index] = num.toFixed(2) + "元";
                }
            });

            return sums;
      }
        
    },
    created() {
        var self = this;

        // detentionWarningApi.schoolList().then(response => {
        //     var jsonData = response.data;
        //     this.schoolList = jsonData.data;
        // });

        goodsInfoApi.goodsTypeList().then(response => {
            var jsonData = response.data;
            this.goodTypeList = jsonData.data;
        });

        registrationFeeApi.schoolClassList().then(response => {
            var jsonData = response.data;
            this.schoolClassList = jsonData.data;
        });

        setTimeout(() => {
            console.log(self.$refs.formTable.$el.offsetTop);

            //页面高度-列表上面的高度-header高度-分页控件高度
            self.tableHeight = pageUtil.autoAdjustHeight(self.$refs.formTable.$el);
        }, 1000);
    },
    mounted() {
        this.changePage(1,0);
    },
    components: {
        "order-detail": orderDetail,
        "order-refundFee-detail": orderRefundFeeDetail,
    },
}
</script>
<style scoped>
.el-divider {
  margin: 5px 0;
}

.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}
.order-list{
    text-align: left;
}
.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>