<template>
    <div>
        <el-dialog
        title="线上退款"
        :visible.sync="showModal2"
        :modal-append-to-body="false"
        style="text-align: left;"
        :close-on-click-modal="false"
        width="500px"
        >
        <el-form ref="form" label-width="80px"  :model="formModel" :rules="ruleValidate">
            <el-input v-model="formModel.id" style="display:none"></el-input>
            <el-form-item label="实付金额">
                <el-input v-model="formModel.payFee" :disabled="true"></el-input>
            </el-form-item>        
            <el-form-item label="退款金额" prop="refundFee">
                <el-input v-model="formModel.refundFee"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">关 闭</el-button>
            <el-button type="primary" @click="handleSubmit" :loading="submitting">确认退款</el-button>
        </div>
        </el-dialog>
    </div>
</template>
<script>
import Constant from "@/constant";
import orderApi from '@/api/base/order'
export default {
    props: ["businessKey", "title"],
    data() {
      return{
        formModel:{
            id:"",
            payFee:"",
            refundFee:"",
        },
        ruleValidate:{
            refundFee: [{ required: true, message: "退款金额不能为空", trigger: "blur" }],
        },
        showModal2: true,
        loading: false,
        submitting: false,
      }
    },
    methods: {
        closeDialog() {
            this.$emit("close", false);
        },
        handleSubmit(){
            var self = this;
            this.$refs["form"].validate(valid => {
                if (valid) {
                    (function() {
                        var id = self.formModel.id;

                        var formData = new FormData();
                        formData.append("id", id);
                        formData.append("payStatus", 40);
                        formData.append("isOff", "0");
                        formData.append("refundFee",self.formModel.refundFee);

                        return orderApi.updateOrder(formData);
                        
                    })().then(function(response) {
                        var jsonData = response.data;

                        if (jsonData.result) {
                            self.$message({
                            message: "退款成功!",
                            type: "success"
                            });
                            self.$emit("close", {
                            result: true,
                            data: jsonData.data
                            });
                        } else {
                            self.$message({
                            message: jsonData.message + "",
                            type: "warning"
                            });

                            self.$emit("close", {
                            result: false
                            });
                        }
                    })

                }
            })

        },
    },
    async mounted() {
        console.log("mounted");

        var self = this;

        (function() {
            return orderApi.detail(self.businessKey);
        })()
        .then(response => {
            var jsonData = response.data;

            if (jsonData.result) {
                self.formModel.payFee = jsonData.data.payFee;
                self.formModel.id = self.businessKey;
            } else {
                self.$message.error(jsonData.message + "");
            }
        })
        .catch(error => {
            self.$message.error(error + "");
        });

    }
    
}
</script>
<style scoped>

</style>